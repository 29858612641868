@use "src/styles/fonts";
@use "src/styles/colors";

.duplicate-project {
  max-width: 475px;

  .error {
    margin-top: theme("spacing.5");
    color: colors.$red6;
    @include fonts.little;
  }
}
