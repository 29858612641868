@use "src/styles/colors";
@use "src/styles/fonts";

:global(.fullscreen) .profile {
  .details,
  .icon {
    display: none;
  }

  .avatar {
    margin: 0 auto;
  }
}

.profile {
  align-items: center;
  display: flex;
  margin: 0 30px;
  @apply pt-7 pb-10;

  &-container {
    align-items: center;
    display: flex;
    flex: 1 1;
    justify-content: space-between;
    overflow: hidden;
  }
}

.details {
  flex: 1 1;
  @apply ml-6 pr-3;
  overflow: hidden;
}

.name {
  @apply font-sm;
  @apply font-bold;
  @apply text-blackberry;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.footer {
  @include fonts.little;
  color: colors.$purple4;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
