@use "src/styles/colors";
@use "src/styles/fonts";

.brand-card {
  @apply border border-solid border-light-blue-steel;
  border-radius: 10px;
  transition: box-shadow 0.2s linear;
  background-color: colors.$white;
  cursor: pointer;
  aspect-ratio: 1/1;
  display: flex;
  flex-direction: column;

  & > div[class] {
    flex-grow: 1;
    overflow: hidden;
    height: auto;
  }

  & > section[class] {
    padding: 0;
    flex-shrink: 0;
  }

  &:hover {
    @apply shadow-[0_5px_20px_0] shadow-blackberry/10;
  }

  &__title {
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 10px 20px;
  }

  .image {
    @apply bg-light-teal;
    img {
      object-fit: cover;
    }
  }
}
