@use "src/styles/colors";
@use "src/styles/fonts";

.container {
  margin-top: 86px;

  a {
    @apply text-sm text-teal font-medium mt-10;
    display: flex;
    align-items: center;

    svg {
      margin-right: theme("spacing.4");
    }
  }
}
