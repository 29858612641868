@use "src/styles/colors";
@use "src/styles/fonts";

.shared-brief {
  padding: theme("spacing.10");
  margin: 0 theme("spacing.10");

  &-title {
    margin-bottom: 120px;
  }

  &-main {
    margin: 0 auto;
    max-width: 760px;
    color: theme("colors.blackberry");
  }
}
