@use "src/styles/fonts";
@use "src/styles/colors";

.create {
  @apply border border-solid border-light-blue-steel;
  border-radius: 10px;
  max-width: 240px;
  overflow: hidden;
  cursor: pointer;

  & > section:first-child {
    padding: 0;
  }

  .description,
  .title {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .title {
    background-color: theme("colors.teal");
    color: colors.$white;

    svg {
      path {
        stroke: colors.$white;
      }
    }
  }

  .description {
    @apply font-bold font-sm text-teal;
  }

  &.regular {
    width: 240px;

    .title {
      height: 180px;

      svg {
        height: 60px;
        width: 60px;
      }
    }

    .description {
      height: 60px;
    }
  }

  &.small {
    width: auto;
    aspect-ratio: 1/1;

    & > section:first-child {
      height: 100%;
    }

    .title {
      height: calc(100% - 52px);

      svg {
        height: 40px;
        width: 40px;
      }
    }

    .description {
      @apply font-xs;
      height: 52px;
    }
  }
}
