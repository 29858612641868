// LEGACY
$regular: 400;
$medium: 500;
$semibold: 600;

// LEGACY
@mixin primary {
  @apply font-display;
}

@mixin tiny {
  font-size: 6px;
  line-height: 12px;
}

@mixin little {
  font-size: 12px;
  line-height: 20px;
}

@mixin small {
  font-size: 14px;
  line-height: 22px;
}

@mixin regular {
  font-size: 16px;
  line-height: 24px;
}

@mixin medium {
  font-size: 20px;
  line-height: 28px;
}

@mixin big {
  font-size: 24px;
  line-height: 32px;
}

@mixin large {
  font-size: 30px;
  line-height: 38px;
}

@mixin heavy {
  font-size: 34px;
  line-height: 46px;
}

@mixin huge {
  font-size: 46px;
  line-height: 54px;
}

@mixin heading {
  font-size: 34px;
  line-height: 46px;
}
