@use "src/styles/colors";
@use "src/styles/fonts";

.divided-list {
  color: colors.$purple4;
  @include fonts.regular;
  word-break: break-word;
  a {
    &:hover {
      text-decoration: underline;
    }
  }
}
