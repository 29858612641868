@use "src/styles/colors";
@use "src/styles/fonts";

.project-card {
  $root: &;
  @apply border border-solid border-light-blue-steel;
  background-color: colors.$white;
  border-radius: 10px;
  transition: box-shadow 0.2s linear;
  overflow: visible;

  &:hover {
    @apply shadow-[0_5px_20px_0] shadow-blackberry/10;
  }

  &__options {
    display: inline;
  }

  &__share {
    padding: theme("spacing.4");
    margin-right: theme("spacing.5");
  }

  &--interactable {
    cursor: pointer;
  }

  &__title {
    @apply text-blackberry font-bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .image img {
    object-fit: cover;
  }

  &-actions {
    &[class] {
      padding: 0;
      margin-left: theme("spacing.7");

      &:hover,
      &:focus,
      &:active {
        box-shadow: none;
      }
    }

    &-menu {
      li {
        padding: 0;
        font-weight: 400;
        color: theme("colors.blue-steel");

        button {
          font-weight: inherit;
          color: inherit;
          transition: none;
        }

        &:last-child {
          padding: 0;
        }

        &:hover {
          background-color: colors.$white;
          color: theme("colors.blackberry");
          font-weight: fonts.$medium;
        }
      }
    }
  }

  &--draft {
    .image {
      @apply from-white/[0.2] to-light-blue-steel/[0.2] bg-gradient-to-r;
    }
  }

  &--deleted {
    .image {
      @apply from-white/[0.2] to-light-blue-steel/[0.2] bg-gradient-to-r;
    }
  }

  &--pending_review {
    .image {
      @apply from-white to-mint bg-gradient-to-r;
    }
  }

  &--clarification_requested {
    .image {
      @apply from-white to-orange/[0.2] bg-gradient-to-r;
    }
  }

  &--queued {
    .image {
      @apply from-white/[0.2] to-green/[0.2] bg-gradient-to-r;
    }
  }

  &--delivered {
    .image {
      @apply from-white/[0.2] to-blue-steel/[0.2] bg-gradient-to-r;
    }
  }

  &--finalized {
    .image {
      @apply from-white/[0.2] to-blue-steel/[0.2] bg-gradient-to-r;
    }
  }

  &--partial_delivery {
    .image {
      @apply from-white to-lilac bg-gradient-to-r;
    }
  }

  &--in_progress {
    .image {
      @apply from-white to-mint bg-gradient-to-r;
    }
  }

  &--under_revision {
    .image {
      @apply from-white to-lilac bg-gradient-to-r;
    }
  }

  &--canceled {
    .image {
      @apply from-white/[0.2] to-red/[0.2] bg-gradient-to-r;
    }
  }

  &--card {
    background-color: colors.$white;
    display: grid;

    .image {
      @apply bg-gradient-to-b;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      overflow: hidden;
    }
    .status {
      border-radius: 50px;
    }

    #{$root} {
      &__description,
      &__options {
        @apply text-xs font-medium text-blue-steel;
      }

      &__title {
        @apply text-sm;
      }
    }
  }

  &--list {
    background-color: auto;
    height: 73px;

    #{$root} {
      &__title {
        @apply text-l;
      }

      &__description,
      &__options {
        @apply text-sm font-medium text-blue-steel;
      }
    }

    .image {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      overflow: hidden;
    }
  }

  &.status {
    background-color: colors.$white;
  }

  .status {
    cursor: inherit;
  }
}
