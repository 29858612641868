@use "src/styles/colors";
@use "src/styles/fonts";

.project-notes {
  display: grid;
  grid-gap: 56px;
  grid-template-columns: auto 240px;
  width: 100%;

  &-empty {
    h2 {
      @include fonts.medium;
      color: colors.$purple6;
      font-weight: fonts.$medium;
      margin-bottom: theme("spacing.7");
    }

    span {
      @include fonts.small;
      color: colors.$black;
    }
  }
}
