@use "src/styles/fonts";
@use "src/styles/colors";

.imageCard {
  border-radius: theme("spacing.7");
  box-shadow: none;
  flex-direction: column-reverse;
  transition: box-shadow 0.5s;
  overflow: visible;
  width: 100%;

  & > div:first-child {
    height: auto;
    background-color: colors.$transparent;
    border-radius: theme("spacing.7");
    position: relative;
    overflow: hidden;
  }

  &.selected {
    & > div:first-child::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      outline: theme("spacing.4") solid theme("colors.light-teal");
      outline-offset: calc(theme("spacing.4") * -1);
      border-radius: theme("spacing.7");
    }
  }

  &:not(&.selected) > section:last-child,
  & > section:nth-last-child(2) {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    overflow: visible;
  }

  &:not(.selected) {
    img {
      filter: grayscale(100%);
    }
  }
}

.image {
  border-radius: theme("spacing.7");
  aspect-ratio: 1/1;

  &:hover {
    .preview {
      opacity: 1;
    }
  }

  img {
    border-radius: theme("spacing.7");
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .preview {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    transition: opacity 0.2s linear;
    width: 100%;
    height: 100%;
    border-radius: theme("spacing.7");
  }

  &--selectable {
    cursor: pointer;

    &:hover {
      border-color: rgba(colors.$black, 0.1);
      box-shadow: 0 9px 28px 8px rgba(colors.$purple6, 0.05),
        0 6px 16px rgba(colors.$purple6, 0.08),
        0 3px 6px -4px rgba(colors.$purple6, 0.08);

      header {
        opacity: 1;
      }
    }
  }

  header {
    @apply font-h3 text-blackberry font-bold;
    align-items: center;
    background-color: colors.$white;
    border-radius: theme("spacing.6");
    display: flex;
    height: 50px;
    inset: 0;
    justify-content: center;
    margin: auto;
    max-width: 80px;
    opacity: 0;
    position: absolute;
    transition: opacity 0.1s;
    width: auto;
  }

  footer {
    @include fonts.little;
    align-items: center;
    background-color: colors.$white;
    border-radius: theme("spacing.4");
    bottom: 11px;
    color: colors.$black9;
    display: flex;
    font-weight: fonts.$medium;
    padding: theme("spacing.2") theme("spacing.5");
    position: absolute;
    right: 11px;
    width: auto;
    height: auto;

    img {
      height: 10px;
    }
  }
}

.title {
  @apply font-h3 text-blackberry mt-6;
  align-items: center;
  display: flex;
  justify-content: space-between;
  word-break: break-word;
}

.description {
  @include fonts.little;
  color: colors.$grey6;
  font-weight: fonts.$medium;

  li {
    color: colors.$black9;
    font-weight: fonts.$regular;
  }
}
