@use "src/styles/colors";
@use "src/styles/fonts";

.container {
  color: colors.$purple6;
  font-weight: fonts.$medium;
  padding-top: theme("spacing.9") ;
  position: relative;
  text-align: center;

  .cancelButton {
    color: colors.$grey7;
    display: none;

    &:hover {
      color: colors.$grey7;
      background-color: colors.$transparent;
    }
  }

  &:not(.edit) {
    &:hover {
      .arrows {
        opacity: 1;
      }
    }
  }

  &.showControls {
    .buttonsContainer {
      margin-top: theme('spacing.5');
      justify-content: space-between;

      .cancelButton {
        display: block;
      }
    }


    .selectContainer {
      display: block;
    }

    &.loading {
      cursor: not-allowed;

      .buttonsContainer {
        justify-content: center;

        .cancelButton {
          display: none;
        }
      }

      .arrows {
        display: none;
      }
    }
  }

}

.inputContainer {
  margin-bottom: theme("spacing.4");
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrows {
  opacity: 0;
  transition: 0.2s opacity;
  position: absolute;
  right: 0px;

  .arrow {
    cursor: pointer;

    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    path {
      transition: 0.1s opacity;
      &:last-child {
        opacity: 0;
      }
    }

    &:hover {
      path {
        &:last-child {
          opacity: 1;
        }
      }
    }

    &.decrease {
      margin-top: theme("spacing.3");
      transform: rotate(180deg);
    }
  }
}

.balance {
  @include fonts.huge;
  font-weight: fonts.$medium;
  cursor: inherit;
  background-color: colors.$transparent;
  border: 1px solid colors.$transparent;
  border-radius: theme("spacing.5");
  outline: 0;
  width: 150px;
  text-align: center;
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::placeholder {

    @apply text-blackberry;
  }
  &:focus {
    background-color: colors.$white;
    border-color: colors.$lavender6;
    &::placeholder {
      color: transparent
    }
  }
}

.title {
  @include fonts.medium;
  padding-top: theme("spacing.6");
  padding-bottom: theme('spacing.6');
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  display: flex;
}

.selectContainer {
  display: none;
  margin-top: theme("spacing.7");

  button {
    width: 100%;
  }
}

.buttonsContainer {
  align-items: center;
  display: flex;
  justify-content: center;


  .button {
    &-enter {
      opacity: 0;
      &-active {
        opacity: 1;
      }
    }
    &-exit {
      opacity: 1;
      &-active {
        opacity: 0;
        transition: opacity 3s ease-in;
      }
    }
  }
}

.modal {
  @include fonts.small;

  h2 {
    @include fonts.medium;
    color: colors.$purple6;
    font-weight: fonts.$medium;
    margin-bottom: theme("spacing.6");
  }
}

.error {
  @include fonts.little;
  color: colors.$red6;
  font-weight: fonts.$regular;
  margin-top: theme("spacing.6");
}
