@use "src/styles/colors";
@use "src/styles/fonts";
@use "src/styles/variables";

:global(.fullscreen) .sidebar {
  width: 132px;

  .logo {
    display: none;
  }

  .collapseButton {
    box-shadow: none;
    margin: 27px auto;
    opacity: 1;
    position: relative;
    right: auto;
    top: auto;

    img {
      display: block;
      margin: 0 auto;
      width: 17px;
    }

    &:hover {
      background-color: colors.$white;
      box-shadow: none;

      .collapseIcon {
        opacity: 1;
      }

      .logoShort {
        opacity: 0;
      }
    }

    &:focus {
      box-shadow: none;
    }

    .collapseIcon {
      opacity: 0;
      transform: rotate(180deg);
    }

    .logoShort {
      opacity: 1;
    }
  }

  .logoShort {
    opacity: 0;
  }

  .listItem {
    display: flex;
    justify-content: center;

    span {
      left: 50%;
      opacity: 0;
      padding: 11px 15px 10px 10px;
      pointer-events: none;
      position: absolute;
      z-index: 1;

      &::after {
        background-color: theme("colors.mint");
        border-radius: 0 15px 15px 0;
        content: "";
        position: absolute;
        transition: width 0.3s;
        width: 0;
        inset: 0;
        z-index: -1;
      }
    }

    &:hover {
      span {
        &::after {
          width: 100%;
        }
      }
    }

    img {
      display: block;
      margin: 0 auto;
    }

    &:hover {
      span {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  &:hover {
    .collapseButton {
      box-shadow: none;
      right: auto;
    }
  }
}

.sidebar {
  @apply bg-white;
  box-shadow: 0px 10px 20px rgba(33, 0, 66, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overscroll-behavior: contain;
  position: fixed;
  top: 0;
  transition: width 0.3s ease-out;
  width: variables.$sidebarWidth;
  z-index: 2;

  &--injected {
    overflow-y: auto;
    &:not(:empty) {
      flex-grow: 1;
      padding: 0 30px;
      padding-bottom: 10px;
      display: flex;
      flex-direction: column;

      & + * {
        display: none;
      }
    }
  }

  & > button.collapseButton {
    align-content: center;
    align-items: center;
    background-color: colors.$white;
    box-shadow: none;
    display: flex;
    justify-content: center;
    height: 50px;
    opacity: 0;
    position: absolute;
    right: -40px;
    top: 35px;
    transition: right ease 0.3s;
    width: 50px;

    img {
      margin: auto;
      max-width: 17px;
    }

    .logoShort {
      opacity: 0;
    }

    &:focus,
    &:hover {
      box-shadow: none;
    }
  }

  &:hover,
  &:focus {
    .collapseButton {
      opacity: 1;
      right: -20px;
    }

    .collapseIcon {
      opacity: 1;
    }
  }

  &-loading {
    padding: 12px 20px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &__icon {
      min-width: 24px;
      height: 24px;
      border-radius: 4px;
      background-color: colors.$grey5;
    }

    &__text {
      width: 100%;
      background-color: colors.$grey5;
      height: 24px;
      border-radius: 4px;
    }
  }

  .logoShort,
  .collapseIcon {
    height: 100%;
    inset: 0;
    position: absolute;
    transition: transform 0.5s, opacity 0.3s;
    width: 100%;
  }

  .additional-item {
    @apply text-sm font-medium text-blue-steel;
    display: flex;
    align-items: center;
    margin-left: 30px;

    a,
    button {
      padding: theme("spacing.5") 0;
    }
  }
}

.logo {
  display: block;
  margin: 42px 0 40px 45px;
  width: 100px;
}

.listWrapper {
  flex: 1;
  overflow-y: auto;
}

.list {
  @apply font-sm;
  @apply text-blue-steel;
  padding: 0 theme("spacing.8");
}

.listItem {
  margin-bottom: theme("spacing.5");
  position: relative;

  .listLink {
    align-items: center;
    background-color: colors.$transparent;
    @apply text-blue-steel;
    font-weight: fonts.$medium;
    display: flex;
    border-radius: 15px;
    padding: 10px 20px;
    transition: background-color 0.2s;

    &:hover,
    &.listLinkActive {
      @apply bg-mint;
      @apply text-teal;

      svg {
        path {
          stroke: theme("colors.teal");
        }
      }
    }

    .icon {
      height: 22px;
      width: 19px;
    }
  }
}
