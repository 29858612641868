@use "src/styles/colors";
@use "src/styles/fonts";

.credit-details {
  border-bottom: 1px solid colors.$grey5;
  display: grid;
  grid-template-columns: 55px auto;
  padding-top: theme("spacing.7");
  padding-bottom: theme("spacing.8");

  &__difference {
    border-radius: 50%;
    background-color: colors.$peach1;
    @include fonts.little;
    color: colors.$peach8;
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    margin-right: 13px;
  }

  &-details {
    @include fonts.small;
    color: colors.$purple6;
    word-break: break-word;

    span {
      @include fonts.little;
      color: colors.$purple4;
      display: block;
    }
  }
}
