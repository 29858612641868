@use "src/styles/colors";
@use "src/styles/fonts";

:global(.support-guide) {
  position: absolute;
  left: 0;
  top: 0;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  padding-bottom: theme("spacing.7");

  &:not(:empty) {
    & + * {
      display: none;
    }
  }
}

.support-guide {
  &__content {
    word-break: break-word;
    white-space: pre-line;
    a {
      text-decoration: underline;
    }

    ul {
      margin: theme("spacing.7") 0;
      padding-left: theme("spacing.7");

      li {
        list-style: disc;
      }
    }

    &-demo[class] {
      @apply mt-6 mb-4;

      &,
      &:hover,
      &:focus,
      &:active {
        @apply bg-white text-blue-steel border-white;

        &:hover {
          @apply bg-blue-steel text-white border-white;

          svg path {
            fill: theme("colors.white");
            stroke: theme("colors.white");
          }
        }

        svg path {
          fill: theme("colors.blue-steel");
          stroke: theme("colors.blue-steel");
        }
      }
    }
  }

  &__button {
    &--regular {
      width: auto;
    }
    &--small {
      width: 18px;
      height: 18px;

      path {
        fill: theme("colors.blue-steel");
        stroke: colors.$white;
      }
    }
  }

  &-wrapper {
    padding: theme("spacing.10");
    background-color: colors.$grey4;
    height: 100%;
    z-index: 1;
    position: relative;
    margin-bottom: theme("spacing.7");

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: theme("spacing.11");

      h2 {
        color: colors.$purple6;
        @include fonts.big;
        font-weight: fonts.$medium;
      }

      svg {
        width: 16px;
        height: 16px;

        path {
          fill: colors.$purple6;
        }
      }
    }
  }

  &-card {
    padding: theme("spacing.7");
    box-shadow: none;

    &__title {
      @include fonts.regular;
      margin-bottom: theme("spacing.7");
      word-break: break-word;
    }

    &__description {
      color: colors.$black9;
      @include fonts.regular;
    }
  }
}
