@use "src/styles/variables";

.container {
  flex: 1;
  padding: 40px 80px 40px calc(80px + variables.$sidebarWidth);
  position: relative;
  overflow-x: hidden;
}

.wrapper {
  display: flex;
  min-height: 100%;
  width: 100%;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
