@use "src/styles/colors";
@use "src/styles/fonts";

.asset-crop {
  @apply flex flex-col gap-5 cursor-default hover:bg-white;

  &-option {
    @apply flex items-center justify-between text-mid-blue-steel;

    &.error {
      @apply text-red;

      input[class] {
        @apply border-red text-red;
      }
    }
  }

  &-input {
    @apply ml-5;
    width: 70px;

    input[class] {
      @apply p-0 shadow-none border-0 border-solid border-b border-light-blue-steel rounded-none text-sm text-center;
    }
  }
}
