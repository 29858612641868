@use "src/styles/colors";
@use "src/styles/fonts";

.media {
  height: 100%;
  width: 100%;
}

.play {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: theme("spacing.10");
  height: theme("spacing.10");
  background: rgba(colors.$white, 0.4);
}

.selection {
  width: 100%;
  height: auto;
  cursor: crosshair;

  &-message {
    @apply fixed bg-blue-steel py-4 px-6 text-sm text-white;
    border-radius: theme("spacing.4");
    z-index: 10;
    white-space: nowrap;
    margin-top: calc(theme("spacing.7") * -1);
    margin-left: theme("spacing.6");

    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border: 0 solid transparent;
      left: calc(theme("spacing.4") * -1);
      top: 50%;
      border-top-width: theme("spacing.4");
      border-bottom-width: theme("spacing.4");
      border-right: theme("spacing.5") solid theme("colors.blue-steel");
      transform: translateY(-50%);
    }
  }

  &-widget {
    position: absolute;
    max-width: max-content;
    margin: 0 theme("spacing.2");
    z-index: 1;
    cursor: default;

    &--bottom {
      top: 100%;
      margin-top: theme("spacing.4");
    }

    &--top {
      top: 0;
      margin-top: calc(theme("spacing.4") * -1);
      transform: translateY(-100%);
    }

    &--left {
      right: 100%;
      top: 0;
      margin-right: theme("spacing.4");
    }

    &--right {
      left: 100%;
      top: 0;
      margin-left: theme("spacing.4");
    }

    &--center {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &--top-left {
      top: 0;
      margin-top: calc(theme("spacing.4") * -1);
      right: 100%;
      transform: translateY(-100%);
    }
  }

  &-index {
    visibility: visible;
    cursor: initial;

    button {
      @apply text-xs font-bold;
      background-color: theme("colors.teal");
      color: colors.$white;
      visibility: visible;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: theme("spacing.2") theme("spacing.5");
      border-radius: 50%;
      aspect-ratio: 1/1;
      width: max-content;
      margin-left: calc(theme("spacing.6") * -1);
      margin-top: calc(theme("spacing.6") * -1);
      position: relative;
      z-index: 1;
    }
  }

  &-view {
    cursor: initial;

    &#{&}--video {
      pointer-events: none;
    }
  }

  &-view &-widget,
  &-view div[data-wrapper="wrapper"] {
    visibility: hidden;
  }

  &-index-active &-index-region,
  div[data-wrapper="wrapper"] {
    &:after {
      @apply bg-teal/20;
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      border: 1px solid theme("colors.teal");
      border-radius: 5px;
      left: 0;
      top: 0;
    }
  }

  &-ratio {
    div[data-dir] {
      display: none;

      &[data-dir="se"] {
        display: block;
        width: 18px !important;
        height: 18px !important;
        right: calc(-1 * theme("spacing.5")) !important;
        bottom: calc(-1 * theme("spacing.5")) !important;
        background-color: theme("colors.teal");
        border-radius: 50%;
        z-index: 1;

        &::after {
          position: absolute;
          content: "";
          background-image: url("./images/resize.svg");
          background-size: cover;
          width: 14px;
          height: 14px;
          top: theme("spacing.2");
          left: theme("spacing.2");
        }
      }
    }
  }

  div[data-dir] {
    border: none !important;
    outline: none !important;
    background-color: theme("colors.teal");
  }
}
