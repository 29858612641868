.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding-right: 20px;

  > * {
   flex-basis: 70px;
   overflow: hidden;

    &:nth-child(1) {
      flex-grow: 5.6;
    }
  
    &:nth-child(2) {
      flex-grow: 1.4;
    }
  
    &:nth-child(3) {
      flex-basis: 120px;
      flex-grow: 0;
    }
  
    &:nth-child(4),
    &:nth-child(5) {
      flex-grow: 0;
    }
  
    &:nth-child(6) {
      flex-grow: 2.6;
    }
  }
}