@tailwind base;
@tailwind utilities;
@import "styles";

body {
  @apply font-display text-blue-steel;
  height: 100%;
}

@layer base {
  .words-break {
    word-break: break-word;
  }

  .resize-none {
    &,
    textarea {
      resize: none;
    }
  }
}

body {
  @include primary;
  height: 100%;
}

html,
#root {
  height: 100%;
}

html,
body,
#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

h1 {
  @apply font-h1 text-blackberry;
}

h2 {
  @apply font-h2 text-blackberry;
}

h3 {
  @apply font-h3 text-blackberry;
}
