@use "src/styles/colors";
@use "src/styles/fonts";

.user-card {
  background-color: colors.$grey2;
  box-shadow: none;
  color: colors.$purple6;
  padding: theme("spacing.4") theme("spacing.5");

  &:hover {
    .login {
      opacity: 0;
    }
  }

  .login {
    @include fonts.little;
    background-color: colors.$white;
    border-radius: theme("spacing.4");
    color: colors.$purple4;
    display: flex;
    font-weight: fonts.$medium;
    padding: theme("spacing.5") theme("spacing.7");
    white-space: nowrap;
  }

  .title {
    @include fonts.medium;
    align-items: flex-start;
    display: flex;
    font-weight: fonts.$medium;
    justify-content: space-between;
  }

  .details {
    display: flex;
    padding-top: 0;
  }

  .label {
    @include fonts.little;
    color: colors.$purple6;
    font-weight: fonts.$medium;
    margin-top: theme("spacing.6");

    .value {
      color: colors.$purple4;
      margin-left: theme("spacing.5");
      word-break: break-word;
    }
  }

  .delete-btn {
    margin-left: theme("spacing.3");

    &:hover path {
      fill: colors.$red6;
    }
  }
}
