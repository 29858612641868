@use "src/styles/fonts";
@use "src/styles/colors";

$drawerWidth: 360px;

.brief-comments {
  &-open[class] {
    position: fixed;
    bottom: 80px;
    right: 80px;

    button {
      width: 100%;
      height: 100%;
      background-color: colors.$white;
      z-index: 1;
      box-shadow: 0px 10px 20px rgba(33, 0, 66, 0.1);
      border-radius: 10px;

      &:hover {
        background-color: theme("colors.grey");
        box-shadow: none;
      }
    }
  }

  &-drawer {
    width: $drawerWidth;
    margin-left: theme("spacing.9");

    &-main {
      width: $drawerWidth;
      box-shadow: 0px 10px 20px 0px rgba(33, 0, 66, 0.1);
      background-color: colors.$white;
      display: flex;
      flex-direction: column;
      position: fixed;
      height: 100vh;
      top: 0;
      right: 0;
      z-index: 2;
    }
  }
}
