@use "src/styles/colors";

.skeleton {
  &--loading {
    background: colors.$grey5;
    background: linear-gradient(110deg,
        colors.$grey3 8%,
        colors.$grey4 18%,
        colors.$grey3 33%);
    color: colors.$transparent;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
    border-radius: 12px;
  }

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
}
