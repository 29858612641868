@use "src/styles/colors";
@use "src/styles/fonts";

.help-center {
  &-menu {
    white-space: nowrap;
    width: 178px;

    li {
      padding: 0;

      &:last-child {
        padding: 0;
      }

      & > * {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 10px 10px 20px;
        font-weight: inherit;
        width: 100%;

        &:hover {
          color: theme("colors.blackberry");
          font-weight: fonts.$medium;

          svg path {
            stroke: theme("colors.blackberry");
          }
        }
      }
    }
  }
}
