@use "src/styles/fonts";
@use "src/styles/colors";

.brief-step {
  height: 100%;
  display: flex;
  flex-direction: column;

  header {
    margin-bottom: theme("spacing.11");
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      @apply text-sm text-mid-blue-steel;
    }
  }

  form {
    flex-grow: 1;
    max-width: 760px;
    width: 100%;
    margin: 0 auto;

    fieldset {
      & > label {
        @apply text-l text-blackberry font-bold;

        span {
          @apply text-sm text-blackberry font-regular;
        }
      }

      & > p {
        @apply text-sm text-mid-blue-steel;
      }
    }

    footer {
      width: 100%;
    }
  }
}

.title {
  margin-bottom: theme("spacing.11");
}

.title-info {
  @include fonts.heavy;
  font-weight: fonts.$semibold;
  color: colors.$purple3;
}

.subTitle {
  @include fonts.regular;
  font-weight: fonts.$regular;
}

.header {
  @include fonts.regular;
  font-weight: fonts.$medium;
  color: colors.$purple6;
  margin-bottom: theme("spacing.4");
}

.details {
  @include fonts.little;
  font-weight: fonts.$medium;
  color: colors.$grey6;
}

.sidebar {
  &-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
  }

  &__title {
    @apply text-l font-bold text-blackberry;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  nav {
    margin-top: theme("spacing.10");
  }

  .delete-button:hover path {
    fill: colors.$red6;
  }
}

.errors {
  @apply text-xs text-blackberry bg-red/10 border border-solid border-red mt-8 mb-6 py-5 px-7;
  border-radius: theme("spacing.5");

  header {
    @apply text-red font-medium;
  }

  dl {
    display: grid;
    margin-top: theme("spacing.7");

    dt {
      @apply font-bold;
    }

    dd {
      align-items: center;
      display: flex;
      font-weight: fonts.$regular;
    }
  }
}

.error {
  .header {
    color: colors.$red6;
  }

  .details {
    color: colors.$red3;
  }
}

.area {
  textarea {
    min-height: 130px;
    resize: none;
  }
}

.options {
  &-list {
    display: flex;
    gap: theme("spacing.8");

    li {
      margin-bottom: theme("spacing.6");
    }
  }

  &-title {
    @include fonts.little;
    padding-top: theme("spacing.8");
    margin: 0;

    img {
      max-width: 14px;
    }
  }

  &-description {
    color: colors.$black;
    font-weight: fonts.$regular;
    padding-top: theme("spacing.7");

    ul {
      li {
        svg {
          min-width: 12px;
          margin: 5px 8px 0 0;

          path {
            fill: colors.$black;
          }
        }
      }
    }
  }
}

.crossIcon {
  width: 8px;
  height: 8px;

  path {
    fill: colors.$red6;
  }
}

.info {
  background-color: colors.$lavender2;
  padding: 36px 42px;
  border-radius: 10px;
  color: colors.$black9;
  @include fonts.regular;

  h5 {
    font-weight: fonts.$medium;
    margin-bottom: theme("spacing.6");
  }
}

.build {
  &--card {
    flex-grow: 1;
    max-width: 380px;
  }
}

.upload {
  &--alert {
    color: colors.$turquoise6;
    margin-bottom: theme("spacing.10");

    & > div:last-child {
      width: 100%;
    }
  }
}

.define {
  &--lifestyle {
    &[class] {
      color: colors.$turquoise6;
    }
  }
}

.modal {
  &-content {
    max-width: 500px;

    h3 {
      @include fonts.medium;
      color: colors.$purple6;
    }

    p {
      margin-top: theme("spacing.5");
      @include fonts.small;
      color: colors.$black9;
    }
  }
}

.brief {
  &-form {
    height: 100%;
    color: theme("colors.blackberry");
    margin: 120px auto theme("spacing.10");
    max-width: 760px;
  }

  &-field {
    @apply flex flex-col mt-8;

    &-title {
      @apply text-l font-bold place-self-start;
    }

    &-description {
      @apply text-sm text-mid-blue-steel;
    }
  }

  &-brand {
    &-input {
      margin-top: theme("spacing.6");

      & > input[class] {
        padding-right: theme("spacing.11");
      }
    }
  }

  &-menu {
    display: flex;
    flex-direction: column;
    margin-top: theme("spacing.10");

    &-item {
      @apply text-sm text-blue-steel font-medium flex items-center py-4 mb-4;

      &.active {
        @apply text-blackberry;

        path {
          stroke: theme("colors.blackberry");
        }
      }

      &-list {
        display: flex;
        flex-direction: column;
        padding-left: 28px;
        margin-top: calc(theme("spacing.4") * -1);

        &-item {
          @apply text-sm font-medium text-light-blue-steel mb-4 text-ellipsis overflow-hidden whitespace-nowrap text-left;

          &.active {
            @apply text-blackberry;
          }
        }
      }
    }
  }
}
