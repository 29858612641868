@use "src/styles/fonts";
@use "src/styles/colors";

.share-brief {
  position: relative;
  padding: theme("spacing.11");
  width: 760px;

  .close {
    @apply absolute right-8 top-8;
  }
}
