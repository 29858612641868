@use "src/styles/colors";
@use "src/styles/fonts";

$header: 103px;
$footer: 80px;

.asset {
  height: 100%;
  max-height: 100%;
  display: flex;

  main {
    padding: theme("spacing.6");
    max-height: calc(100% - $header - $footer);
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    & > section {
      height: 100%;
    }

    aside {
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: theme("spacing.6");
      button {
        padding: 0;
      }
    }
  }

  &-content {
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__media {
    margin: auto;
    height: auto;
    transition: opacity 0.3s linear;
    opacity: 1;
    max-height: calc(100vh - 208px);
    width: auto;
    &--loading {
      opacity: 0;
      visibility: hidden;
    }
  }

  &-action {
    background-color: transparent;
    padding: theme("spacing.5");
    border-radius: theme("spacing.5");

    &:hover:not(:disabled),
    &--active {
      @apply bg-grey;
    }

    &__count {
      position: absolute;
      top: 10px;
      left: 11px;
      width: 18px;
      height: 16px;
      text-align: center;
      font-size: 9px;
      line-height: 18px;
      color: colors.$purple6;
    }

    & > div {
      margin: 5px;
    }

    &--selected {
      svg {
        path {
          fill: theme("colors.blue-steel");
          stroke: colors.$white;
        }
      }
    }

    &--selected {
      p {
        color: colors.$white;
      }
    }
  }

  &__comments {
    border-left: 1px solid colors.$purple1;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 360px;
    flex-grow: 0;
    flex-shrink: 0;
    overflow: hidden
  }

  &-details {
    @apply grid mx-8;

    &-name {
      @apply font-medium text-blackberry text-center mb-6 text-ellipsis overflow-hidden whitespace-nowrap;
    }

    &-list {
      @apply grid gap-4 items-center grid-flow-col;
    }

    &-label {
      @apply text-ellipsis overflow-hidden whitespace-nowrap;
    }
  }
}
