@use "src/styles/colors";
@use "src/styles/fonts";

.select-asset-version {
  $root: &;

  &-dropdown[class] {
    &,
    &:hover,
    &:focus {
      @apply border-light-blue-steel text-blackberry text-base font-regular mr-6;

      svg path {
        @apply stroke-blue-steel;
      }
    }

    .select-asset-version-info {
      display: none;
    }
  }

  &-info {
    @apply block text-xs whitespace-nowrap;
  }

  &-item {
    @apply text-blue-steel relative;

    &:hover,
    &-selected {
      @apply text-blackberry;
      background-color: unset;
    }

    &:hover {
      #{$root} {
        &-remove[class] {
          @apply block;
        }
      }
    }
  }

  &-remove[class] {
    @apply absolute hidden right-4 top-4 p-4;
  }
}
