@property --gridSize {
  syntax: "<integer>";
  initial-value: 0;
  inherits: false;
}

.asset-library {
  &-grid {
    @apply grid items-stretch gap-7;
    grid-template-columns: repeat(var(--gridSize), minmax(0, 1fr));
  }

  &-view {
    &-active {
      path {
        @apply fill-blackberry;
      }
    }
  }
}

.columns {
  @apply font-sm;
  color: theme("colors.mid-blue-steel");
  height: 20px;
  margin-bottom: 20px;
}

.type-icon {
  @apply cursor-pointer w-7 h-7;
}
