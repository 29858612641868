.search[class] {
  @apply w-full;

  input {
    @apply py-5;
  }

  svg {
    @apply mb-3;
  }
}
