@use "src/styles/colors";

.tooltip {
  margin-top: calc(theme("spacing.6") * -1);

  &.in-active {
    @apply text-blue-steel bg-white;

    & > div[class]:last-child {
      border-top-color: colors.$white;
    }
  }
}
