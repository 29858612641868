@use 'src/styles/colors';
@use 'src/styles/fonts';

.project-assets {
  h2 {
    color: colors.$purple6;
    @include fonts.regular;
    font-weight: fonts.$medium;
    margin-bottom: theme('spacing.8');
  }
  &-list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: auto;
      grid-column-gap: theme('spacing.7');
      grid-row-gap: theme('spacing.7');
  }
  &-empty {
    h2 {
      @include fonts.medium;
      color: colors.$purple6;
    }
    span {
      @include fonts.small;
      color: colors.$black;
    }
  }
}
