@use "src/styles/colors";
@use 'src/styles/fonts';

.divider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: theme('spacing.6') 0;

  hr {
    flex: 1;
    @apply border-light-blue-steel;
  }

  span {
    display: inline-block;
    @apply mx-6 font text-light-blue-steel;
  }
}

.forgot-password-button {
  @apply text-teal font-sm;

  &:hover {
    text-decoration: underline;
  }
}

