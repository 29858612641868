@use 'src/styles/colors';
@use 'src/styles/fonts';


.concept-deliverables {
  &-list {
    &__item {
      border-radius: 20px;
      background-color: colors.$lavender1;
      padding: 20px 28px;
      margin-bottom: theme('spacing.7');
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: colors.$black9;
      font-weight: fonts.$medium;

      &__title {
        @include fonts.regular;
      }

      &__amount {
        @include fonts.medium;
      }
    }
  }
}
