@use "src/styles/colors";
@use "src/styles/fonts";

.label {
  display: inline-flex;
  flex-shrink: 0;
}

.icon {
  width: 14px;
}

.info {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
