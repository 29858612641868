.selector {
  @apply h-full justify-center flex flex-col after:rounded-lg;
  min-height: 100px;
}

.details {
  @apply mt-2 max-w-full px-2;

  &-name {
    @apply text-sm text-blackberry text-ellipsis overflow-hidden whitespace-nowrap;
  }

  &-info {
    @apply flex justify-between items-center mt-3;

    &-meta {
      @apply text-xs text-blue-steel text-ellipsis overflow-hidden whitespace-nowrap;
    }

    &-labels {
      @apply grid gap-4 grid-flow-col text-xs text-blackberry font-medium uppercase;

      span {
        @apply px-4 py-1 bg-grey;
        border-radius: theme("spacing.3");
      }
    }
  }
}