@use "src/styles/colors";
@use "src/styles/fonts";

.item-selector {
  position: relative;

  &:not(.noop):hover {
    &:after {
      background: linear-gradient(
        rgba(colors.$black, 0.6) 0%,
        colors.$transparent 50%
      );
    }

    .select,
    .star {
      visibility: visible;
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  &.active {
    &:after {
      @apply shadow-[0_0_0_4px] shadow-teal/20;
      border: theme("spacing.2") solid theme("colors.teal");
    }

    .select {
      visibility: visible;
    }
  }

  &.starred {
    .star {
      visibility: visible;
    }
  }

  .select,
  .star {
    display: flex;
    visibility: hidden;
    position: absolute;
    z-index: 1;
  }

  .select {
    left: theme("spacing.7");
    top: theme("spacing.7");
  }

  .star {
    right: theme("spacing.7");
    top: 14px;
  }
}
