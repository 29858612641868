@use 'src/styles/colors';
@use 'src/styles/fonts';

.user-management {
  &__name {
    @include fonts.heading;
    color: colors.$purple3;
    font-weight: fonts.$semibold;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &-form {
    fieldset {
      display: flex;
      margin: 0 calc(-1 * theme('spacing.7'));
      margin-bottom: theme('spacing.11');
      &>* {
        flex: 1 1 0;
        margin-right: theme('spacing.7');
        margin-left: theme('spacing.7');

      }
    }
    &__label {
      color: colors.$purple6;
      @include fonts.medium;
      font-weight: fonts.$medium;

    }
  }
}
