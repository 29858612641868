.section-popup {
  // to cover the hover area with comment button
  position: relative;
  margin-right: -80px;
  padding-right: 80px;

  &-error {
    @apply bg-white;
    position: absolute;
    left: -170px;
    top: theme("spacing.4");
    width: 160px;
    z-index: 2;

    &-message {
      @apply px-7 py-5 text-sm text-blackberry bg-red bg-opacity-10;
      box-shadow: none;
      border: 1px solid theme("colors.red");
      border-radius: 10px;
    }
  }

  &-comment {
    $comment: &;
    position: absolute;
    right: theme("spacing.8");
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;

    &-button[class] {
      @apply p-5;

      &:disabled {
        cursor: default;
      }

      &.active {
        &,
        &:hover,
        &:focus,
        &:active {
          @apply bg-teal;

          svg path {
            @apply stroke-white;
          }

          #{$comment} {
            &-counter {
              background: none;
              top: theme("spacing.5");
              right: 9px;
            }
          }
        }
      }
    }

    &-counter {
      @apply text-xs absolute -top-2 -right-2 bg-teal text-white px-2 py-2;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      aspect-ratio: 1 / 1;
      min-width: 22px;
    }

    &-form {
      position: absolute;
      top: 0;
      right: 80px;
      width: 237px;
      z-index: 1;

      .textarea {
        height: 138px !important;
      }
    }
  }
}
