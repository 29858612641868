@use "src/styles/colors";
@use "src/styles/fonts";

.user-management {
  @apply grid gap-8;

  fieldset {
    display: flex;
    margin: 0 calc(-1 * theme("spacing.7"));
    margin-top: theme("spacing.10");

    &:first-of-type,
    &:empty {
      margin-top: 0;
    }

    & > * {
      flex: 1 1 0;
      margin-right: theme("spacing.7");
      margin-left: theme("spacing.7");
    }
  }

  &__label {
    @apply text-l font-bold text-blackberry;
  }
}
