@use "src/styles/colors";

.status {
  color: colors.$white;

  &--draft {
    @apply bg-light-blue-steel;
  }

  &--deleted {
    @apply bg-red;
  }

  &--pending_review {
    @apply bg-mint text-teal;
  }

  &--clarification_requested {
    @apply bg-orange;
  }

  &--queued {
    @apply bg-green;
  }

  &--finalized {
    @apply bg-blackberry;
  }

  &--delivered {
    @apply bg-blue-steel;
  }

  &--needs_changes {
    @apply bg-purple;
  }

  &--needs_review {
    @apply bg-orange;
  }

  &--accepted {
    @apply bg-green;
  }

  &--in_progress {
    @apply bg-teal;
  }

  &--under_revision {
    @apply bg-purple;
  }

  &--canceled {
    @apply bg-red;
  }

  &--partial_delivery {
    @apply bg-light-purple text-purple;
  }

  &--proposal {
    @apply bg-light-teal text-blue-steel;
  }

  &.loading {
    background: colors.$grey5;
    background: linear-gradient(
      110deg,
      colors.$grey3 8%,
      colors.$grey4 18%,
      colors.$grey3 33%
    );
    color: colors.$transparent;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
}
