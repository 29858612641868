@use "src/styles/colors";
@use "src/styles/fonts";

.credits {
  @apply flex pt-10;
  display: flex;
  align-items: flex-start;

  &-history {
    @apply ml-10;
    flex-grow: 1;

    h2 {
      color: colors.$purple6;
      @include fonts.medium;
    }

    p {
      margin-top: theme('spacing.6');
      @include fonts.small;
      color: colors.$black;
    }
  }

  &-plans {
    height: auto;
    flex: 0 0 280px;
    @apply mr-10;

  }

  &-card {
    border: 1px solid theme('colors.light-blue-steel');
    @apply p-5;
    border-radius: 10px;
  }
}
