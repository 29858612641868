@use "src/styles/colors";
@use "src/styles/fonts";

.gallery-rating {
  background-color: theme("colors.grey");
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  padding: 115px 80px 45px;
  position: relative;

  p {
    @apply text-sm text-blue-steel mt-3 mb-10;
  }

  textarea {
    resize: none;
  }

  &__close {
    position: absolute;
    top: theme("spacing.8");
    right: theme("spacing.8");

    svg {
      width: theme("spacing.8");
      height: theme("spacing.8");
    }
  }

  &__options {
    display: flex;
    gap: theme("spacing.6");

    &__item {
      background-color: colors.$white;
      border-radius: 10px;
      padding: theme("spacing.6");
      transition: background-color 0.2s;
      cursor: pointer;

      &--active {
        background-color: theme("colors.blue-steel");

        svg path {
          stroke: colors.$white;
        }
      }
    }
  }
}
