@use "src/styles/fonts";
@use "src/styles/colors";

.brief-credits {
  margin-top: theme("spacing.8");
  padding: theme("spacing.9") theme("spacing.10");
  background-color: theme("colors.grey");
  border-radius: 10px;

  &-projects {
    margin-bottom: theme("spacing.6");
    padding-bottom: theme("spacing.6");
    border-bottom: 1px solid theme("colors.light-blue-steel");
  }

  &-title {
    @apply text-sm text-blue-steel font-bold;
  }

  &-item {
    @apply flex justify-between text-sm mb-4 text-blue-steel;
  }

  &-options {
    @apply text-sm text-blue-steel;

    span {
      @apply text-light-blue-steel;
    }
  }
}
