@use 'src/styles/colors';
@use 'src/styles/fonts';

.project-comments {
  &-empty {
    margin-top: theme('spacing.10');
    h2 {
      @include fonts.medium;
      color: colors.$purple6;
      font-weight: fonts.$medium;
      margin-bottom: theme('spacing.7')
    }
    span {
      @include fonts.small;
      color: colors.$black;
    }
  }
}
