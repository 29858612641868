.add-to-collection[class] {
  @apply w-full pb-9 relative flex flex-col h-5/6;
  max-width: 852px;

  .close {
    @apply absolute right-8 top-8;

    svg {
      @apply w-10 h-10;
    }
  }

  .card {
    @apply flex items-center justify-center bg-grey;
    border-radius: 10px;
    min-height: 244px;
    cursor: pointer;

    &::after {
      border-radius: 10px;
      background: none;
    }

    &.active {
      @apply bg-teal;

      svg path {
        @apply stroke-white;
      }
    }

    .plus {
      width: 60px;
      height: 60px;

      path {
        @apply stroke-light-blue-steel;
      }
    }
  }
}
