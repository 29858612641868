@use "src/styles/colors";
@use "src/styles/fonts";

.brand-references {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: theme("spacing.4");

  &-upload {
    aspect-ratio: 1/1;
    margin-bottom: 36px;
  }
}
