.card {
  border-radius: 10px;
  @apply border border-light-blue-steel cursor-pointer;

  &.selected {
    @apply border-blackberry;

    .title,
    .description {
      @apply text-blackberry;
    }
  }

  &.error {
    @apply border-red;
  }

  &.disabled {
    @apply cursor-not-allowed opacity-50;
  }

  .title {
    @apply text-light-blue-steel font-bold;
  }

  .description {
    @apply text-light-blue-steel text-xs;
  }

  .info {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}