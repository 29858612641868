@use "src/styles/colors";
@use "src/styles/fonts";

.customer-settings {
  &-card {
    background-color: colors.$grey2;
    color: colors.$purple6;
    padding: theme("spacing.4") theme("spacing.5");

    &__label {
      @include fonts.little;
      color: colors.$purple6;
      font-weight: fonts.$medium;
      margin-top: theme("spacing.6");
      word-break: break-word;

      &__value {
        color: colors.$purple4;
        margin-left: theme("spacing.5");
      }
    }

    h3 {
      @include fonts.small;
      color: colors.$purple6;
      font-weight: fonts.$medium;
    }

    &__explanation {
      @include fonts.little;
      color: colors.$purple6;
      margin-top: theme("spacing.6");
    }

    .actions {
      display: flex;
      justify-content: space-between;
      margin-top: theme("spacing.5");

      .cancel {
        padding: 0;
        display: none;
        color: colors.$grey7;

        &:hover {
          color: colors.$grey7;
          background-color: colors.$transparent;
        }
      }

      .exit {
        opacity: 1;

        &-active {
          opacity: 0;
          transform: scale(0.9);
          transition: opacity 2s, transform 2s;
        }
      }
    }

    .show-controls {
      .cancel {
        display: block;
      }

      &.loading {
        cursor: not-allowed;

        .cancel {
          display: none;
        }
      }
    }
  }
}
