@use 'src/styles/fonts';
@use 'src/styles/colors';

.deleteUser {
  max-width: 480px;

  .title {
    @include fonts.medium;
    font-weight: fonts.$medium;
    color: colors.$purple6;
  }

  .explanation {
    @include fonts.small;
    color: colors.$grey8;
    margin-top: theme('spacing.5');
  }
}