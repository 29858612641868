@use "src/styles/colors";
@use "src/styles/fonts";

.comments-bar {
  padding: theme("spacing.9") 0;

  header {
    border-bottom: 1px solid theme("colors.light-blue-steel");
  }

  main[class] {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
    overflow-y: auto;
    padding: 0;
  }

  &__list {
    width: 100%;
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: theme("spacing.8");

    &-item {
      @apply py-6 pr-8 pl-10;

      div[class*="replies"] {
        @apply -mr-8 -ml-10;

        & > div {
          @apply pl-10;
        }
      }
    }
  }

  &__empty {
    @apply text-base text-light-blue-steel text-center self-center;

    h3 {
      color: inherit;
      margin-bottom: theme("spacing.6");
    }
  }
}
