@use "src/styles/colors";
@use "src/styles/fonts";

.projects-list {
  &--grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: theme("spacing.8");
    grid-row-gap: theme("spacing.8");
  }

  &--list {
    display: block;
  }
}
