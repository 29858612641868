@use "src/styles/colors";
@use "src/styles/fonts";

.visual-guide {
  padding: theme("spacing.6");
  border: 1px solid theme("colors.light-blue-steel");
  border-radius: 10px;

  &-fields {
    &__name {
      width: 100%;

      div {
        padding: 0;
      }

      input[class] {
        @apply font text-blackberry font-bold;
        box-shadow: none;
        border-radius: 0;
        padding: 0;
        padding-bottom: 5px;
        margin-bottom: 5px;

        &::placeholder {
          @apply font text-light-blue-steel font-bold;
        }

        &:read-only {
          cursor: default;
          &::placeholder {
            color: transparent;
          }
        }
      }
    }
    &__info {
      border-radius: 0;
      textarea[class] {
        min-height: auto;
        resize: none;
        padding: 0;
        line-height: 20px;
        border: 0px;
        border-radius: 0;
        box-shadow: none;
        height: 60px;

        &:read-only {
          cursor: default;
          &::placeholder {
            color: transparent;
          }
        }
      }
    }
    &--error {
      input[class] {
        color: theme("colors.red");
      }
      textarea[class] {
        color: theme("colors.red");
      }
    }
  }
  &__media {
    position: relative;

    &:hover {
      .options {
        display: flex;
      }
    }

    &__card {
      img {
        border: 1px solid theme("colors.light-blue-steel");
        border-radius: 10px;
      }
      p {
        display: none;
      }
    }
  }
  &__upload {
    display: flex;
    aspect-ratio: 1/1;
    position: relative;

    &-placeholder {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      border-radius: 10px;
      border: 1px solid theme("colors.light-blue-steel");
      background: theme("colors.grey");
    }

    &:hover {
      .remove {
        opacity: 1;
      }
    }

    .remove {
      position: absolute;
      right: theme("spacing.4");
      top: theme("spacing.4");
      opacity: 0;
      transition: opacity 0.2s linear;
    }
  }
  .options {
    position: absolute;
    right: theme("spacing.4");
    top: theme("spacing.4");
    flex-direction: column;
    align-items: flex-end;
    display: none;

    &-icon {
      padding: theme("spacing.5");
      background-color: colors.$white;
    }

    &-menu {
      background-color: colors.$white;
      margin-top: theme("spacing.4");
      border-radius: 10px;
      box-shadow: 0px 10px 20px 0px rgba(33, 0, 66, 0.1);

      button {
        padding: theme("spacing.6");
        font-weight: fonts.$regular;
        width: 100%;

        &:hover {
          background-color: colors.$lavender1;
        }
      }
    }
  }
}
