@use "src/styles/colors";
@use "src/styles/fonts";

.asset-preview {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  overflow: hidden;
  border-radius: 16px;
  position: relative;
  background-color: colors.$purple1;
  // Fix for safari border-radius
  transform: translateZ(0);

  span {
    color: rgba(colors.$white, 0.75);
    @include fonts.medium;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    text-align: center;
    transform: translate(-50%, -50%);
    transition: opacity 0.2s;
    z-index: 2;
    opacity: 0;
  }

  &__placeholder {
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &::before {
    content: "";
    background-color: rgba(colors.$turquoise6, 0.75);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 2;
    transition: opacity 0.2s;
    pointer-events: none;
  }


  &:hover {
    &::before {
      opacity: 1;
    }

    span {
      opacity: 1;
    }
  }



  &-media {
    height: 100%;

    & > section {
      height: 100%;

      img,
      video {
        object-fit: cover;
      }
    }
  }
}
