@use "src/styles/colors";
@use "src/styles/fonts";

.container {
  .select,
  &.not-clickable {
    .asset .media {
      cursor: default;

      &::after {
        display: none;
      }
    }
  }

  .asset {
    position: relative;
    width: 100%;
    margin-bottom: 10px;

    &:hover {
      .download,
      .details {
        visibility: visible;
      }
    }

    .media {
      height: 100%;
      cursor: pointer;

      &::after {
        border-radius: 10px;
      }

      img,
      video {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }

    &--details {
      .media {
        img,
        video {
          border-radius: 10px 10px 0 0;
        }
      }

      .info {
        padding: theme("spacing.5") theme("spacing.7");
        color: theme("colors.blackberry");

        &--title {
          @apply text-xs font-medium text-ellipsis overflow-hidden whitespace-nowrap;
        }

        &-actions {
          &[class] {
            padding: 0;
            margin-left: theme("spacing.4");

            &:hover,
            &:focus,
            &:active {
              box-shadow: none;
            }
          }

          &-menu {
            li {
              padding: 0;
              font-weight: 400;
              color: theme("colors.blue-steel");

              button {
                font-weight: inherit;
                color: inherit;
                transition: none;
              }
            }
          }
        }

        &--label {
          @apply flex items-center px-4 py-1 text-xs font-medium mr-4 overflow-hidden whitespace-nowrap max-w-full;

          img {
            @apply w-6 h-6;
          }
        }
      }
    }

    &--details:not(&--selected) .media:after {
      border: 1px solid theme("colors.light-blue-steel");
    }
  }

  .details {
    @apply absolute grid gap-4 grid-flow-col right-8 top-8 text-xss font-medium text-blackberry pointer-events-none invisible;

    &--item {
      @apply bg-grey flex items-center py-1 px-4;
      border-radius: theme("spacing.3");
    }
  }
}
