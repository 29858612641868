@use "src/styles/colors";
@use "src/styles/fonts";

.settings-profile {
  @apply text-blackberry;

  .avatar {
    width: 100px;
    height: 100px;
  }

  .field {
    display: flex;
    align-items: center;
    margin-bottom: theme("spacing.6");
    min-width: 0;

    &-label {
      @apply text-base;
      display: block;
      width: 150px;
      margin-right: theme("spacing.7");
    }

    &-info {
      @apply block text-sm text-mid-blue-steel;
    }

    &-input {
      width: 100%;
      max-width: 500px;
    }

    .email {
      @apply text-base font-bold mb-5;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 1;
    }
  }
}
