@use "src/styles/fonts";
@use "src/styles/colors";

.summary {
  &-form {
    height: 100%;
    color: theme("colors.blackberry");
    margin: 120px auto theme("spacing.10");
    max-width: 760px;

    &-field {
      @apply flex flex-col mt-8 items-start;

      &-title {
        @apply text-sm text-mid-blue-steel mb-4;
      }

      &-description {
        @apply text-base;
      }
    }
  }
}
