@use "src/styles/colors";

.container {
  padding: 0 !important;

  > div:last-child {
    z-index: 50 !important;
    background-color: transparent !important;
  }
}

.list {
  @apply border border-light-blue-steel rounded-md shadow-md overflow-y-auto bg-white;

  max-height: 350px;
  max-width: 300px;

  &.loading {
    @apply flex items-center justify-center;

    height: 100px;
    width: 100%;
  }

  .empty {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 60px;
  }
}
