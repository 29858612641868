@use "src/styles/colors";
@use "src/styles/fonts";

.gallery {
  &-section {
    h2 {
      @include fonts.medium;
      font-weight: fonts.$medium;
      color: colors.$purple6;
    }
    p {
      @include fonts.small;
      font-weight: fonts.$regular;
      color: colors.$black9;
    }
    span {
      @include fonts.little;
      font-weight: fonts.$medium;
      color: colors.$grey6;
    }
  }
  &-empty {
    margin-top: theme("spacing.10");
    h2 {
      @include fonts.medium;
      color: colors.$purple6;
      font-weight: fonts.$medium;
      margin-bottom: theme("spacing.7");
    }
    span {
      @include fonts.small;
      color: colors.$black;
    }
  }
  &-info {
    border-top: 1px solid colors.$grey5;
    padding-top: theme("spacing.8");
    p {
      @include fonts.small;
      color: colors.$purple6;
    }
    span {
      @include fonts.small;
      color: colors.$black;
    }
  }
  &-view {
    &-active {
      path {
        @apply fill-blackberry;
      }
    }
  }
}

.error-message {
  color: colors.$red6;
}

.alert-message {
  @apply bg-teal/10 text-sm text-blackberry;
  border-radius: 10px;
  padding: theme("spacing.6") theme("spacing.8");
  margin-bottom: theme("spacing.8");

  h4 {
    @apply font-medium text-teal;
  }
}

.deliverables {
  @include fonts.small;
  font-weight: fonts.$medium;
  color: colors.$white;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: colors.$purple6;
  border-radius: theme("spacing.7");
  padding: theme("spacing.7") theme("spacing.7") theme("spacing.3");
}

.header {
  height: 382px;

  & > * {
    height: 100%;
  }

  &-action[class]:hover {
    @apply bg-teal;
  }
}

.cover {
  position: relative;
  background-color: theme("colors.blackberry");

  &,
  & > img {
    border-radius: 10px;
  }

  &-info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    color: colors.$white;
    font-weight: fonts.$medium;
    text-align: center;
    white-space: nowrap;
    padding: theme("spacing.8");

    h5 {
      @apply text-sm;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    h4 {
      @apply text-2xl font-bold;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
