.container {
  @apply border border-solid border-light-blue-steel cursor-pointer relative;

  &.list {
    height: 73px;

    .description {
      @apply text-sm;
      margin-top: 5px;
    }
  }

  .description {
    @apply text-xs font-medium;
  }

  .placeholder-image {
    width: 46px;
    height: 45px;
  
    path {
      stroke: theme("colors.light-blue-steel");
    }
  }
}