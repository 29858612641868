@use "src/styles/fonts";
@use "src/styles/colors";

.deleteNote {
  width: 476px;

  .title {
    @include fonts.medium;
    font-weight: fonts.$medium;
    color: colors.$purple6;
  }

  .explanation {
    @include fonts.small;
    color: colors.$grey8;
    margin-top: theme("spacing.5");
  }
}
