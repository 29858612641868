// neutrals

$transparent: transparent;
$white: #fff;
$black: #000;
$black9: #0f0f0f;
$grey2: #f9f9f9;
$grey3: #f6f6f6;
$grey4: #f0f0f0;
$grey5: #e8e8e8;
$grey6: #bfbfbf;
$grey7: #8c8c8c;
$grey8: #595959;

// purple

$purple1: #e9e6ea;
$purple2: #d3ced6;
$purple3: #a79ead;
$purple4: #7b6d85;
$purple5: #4f3d5c;
$purple6: #3a2548;
$purple7: #2d1c38;
$purple8: #7b6d85;

// turquoise

$turquoise1: #f4fbfb;
$turquoise2: #cfeced;
$turquoise3: #9fd9db;
$turquoise4: #87cfd2;
$turquoise5: #57bcc0;
$turquoise6: #3fb3b7;
$turquoise7: #27aaaf;
$turquoise8: #1e8185;
$turquoise9: #3fb3b7;

// lavender

$lavender1: #fcfbff;
$lavender2: #f3f2f7;
$lavender3: #d1cce0;
$lavender4: #c5bfd8;
$lavender5: #bab3d1;
$lavender6: #aea6c9;
$lavender7: #988dba;
$lavender8: #877da5;
$lavender9: #aea6c9;

// powrder
$powder4: #9ECDBC;
$powder4_ligh: #DEEAE6;
$powder4_blend: #9ECDBC;

// peach

$peach1: #fef7f3;
$peach2: #fef0e8;
$peach3: #fde9dd;
$peach4: #fcdac6;
$peach5: #fcd3bb;
$peach6: #fbc5a4;
$peach7: #fbbe99;
$peach8: #fcae7e;
$peach9: #fbc5a4;

// extended

$heliotrope: #ad9798;
$opal: #b3d0d0;
$melon: #fff0f0;
$powder: #a8dcd9;
$purpureus: #a14da0;
$teal: #678787;
$teal6: #76AC9F;
$myrtle: #3a7875;
$midred: #9d585d;
$teal6blend: #82bcae;

// success

$green1: #f3f8f1;
$green2: #e7f1e4;
$green3: #dcead6;
$green4: #c4ddbb;
$green5: #adcfa0;
$green6: #96c286;
$green7: #87b975;
$green8: #79b164;

// errors

$red1: #f7e4e4;
$red2: #f2dcdc;
$red3: #db9f9f;
$red4: #c96f6f;
$red5: #b73f3f;
$red6: #af2727;
$red7: #9b2222;
$red8: #851e1e;


// melon
$melon3: #EDC6C6;
