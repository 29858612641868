.notifications {
  &__alert {
    display: inline-flex;
    position: fixed;
    right: 0;
    top: 30px;
    z-index: 10;
    transform: translateX(-53px);
  }

  &__animation {
    &-enter {
      transform: translateX(100%);

      &-active {
        transform: translateX(-53px);
        transition: transform 200ms;
      }
    }

    &-exit {
      transform: translateX(-53px);

      &-active {
        transform: translateX(100%);
        transition: transform 200ms;
      }
    }
  }
}
