@use "src/styles/colors";
@use "src/styles/fonts";

.action-bar {
  display: flex;
  align-items: center;
  border-radius: 10px;
  background: colors.$white;
  box-shadow: 0px 10px 20px rgba(33, 0, 66, 0.1);
  position: fixed;
  padding: theme("spacing.8") theme("spacing.7");
  bottom: 80px;
  right: 80px;
  z-index: 1;

  & > * {
    display: flex;
    align-items: center;
    padding: 0 theme("spacing.8");

    &:not(:last-child) {
      border-right: 1px solid theme("colors.light-blue-steel");
    }
  }
}
