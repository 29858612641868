@use "src/styles/colors";
@use "src/styles/fonts";

$size: 50px;

.avatar {
  height: $size;
  width: $size;
  font-size: 12px;
  position: relative;
  padding: 3px;

  &--editable {
    label {
      cursor: pointer;
    }

    &:hover {
      font-size: 0;
    }
  }

  &--editable:hover &__placeholder {
    @apply bg-teal;

    &::after {
      transform: translate(-50%, -50%) scale(100%);
    }
  }

  &__upload {
    transition: opacity 0.1s ease-in;
    visibility: hidden;
    opacity: 0;
    margin-top: theme("spacing.3");

    label {
      @include fonts.little;
      color: colors.$grey7;
      text-align: center;
      display: block;
      &:hover {
        color: colors.$purple6;
      }
    }

    input {
      display: none;
    }

    &__error {
      margin-top: theme("spacing.4");
      line-height: 5px;
      @include fonts.little;
      color: theme("colors.red");
      text-align: center;
    }
  }

  &:hover &__upload {
    visibility: visible;
    opacity: 1;
  }

  &:hover &__placeholder.image-placeholder {
    background-color: colors.$lavender7;
  }

  &__placeholder {
    @apply bg-light-teal text-teal;
    align-items: center;
    transition: background-color, font-size 0.1s ease;
    border-radius: 50%;
    display: flex;
    width: 100%;
    justify-content: center;
    text-transform: capitalize;
    aspect-ratio: 1/1;
    position: relative;
    &::after {
      transition: transform 0.1s ease;
      content: "";
      background-image: url("./images/picture.svg");
      position: absolute;
      top: 50%;
      left: 50%;
      width: 24px;
      height: 21px;
      transform: translate(-50%, -50%) scale(0);
    }
    &.image-placeholder {
      background-image: url("./images/placeholder.svg");
      background-position: center;
      background-color: transparent;
      background-blend-mode: multiply;
    }
  }

  &__loader {
    z-index: 2;
    top: 0;
    position: absolute;
    width: 100%;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__picture {
    border-radius: 50%;
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    &--admin {
      box-shadow: 0px 0px 0px 1px colors.$turquoise6,
        0px 0px 0px 3px colors.$turquoise2;
    }
  }
}
