@use "src/styles/fonts";
@use "src/styles/colors";

.revertStatus {
  max-width: 480px;

  .title {
    @include fonts.medium;
    font-weight: fonts.$medium;
    color: colors.$purple6;
  }

  .explanation {
    @include fonts.small;
    color: colors.$grey8;
    margin-top: theme("spacing.5");
  }

  .error {
    margin-top: theme("spacing.5");
    color: colors.$red6;
    @include fonts.little;
  }
}
