@use "src/styles/colors";
@use "src/styles/fonts";

.settings-account {
  @apply text-blackberry;

  .title {
    @apply text-l mb-8;
  }

  .billing {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: theme("spacing.10") 0;
    padding: theme("spacing.10") 0;
    border-color: theme("colors.light-blue-steel");
    border-width: 1px 0;
    border-style: solid;
  }

  .field {
    display: flex;
    align-items: center;
    margin-bottom: theme("spacing.6");

    & > p {
      @apply text-base;

      &:first-child {
        margin-right: theme("spacing.8");
        width: 150px;
      }

      &:last-child {
        @apply font-bold;
      }
    }
  }
}
