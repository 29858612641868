.container {
  max-width: 510px;
  margin: 90px auto 0;
  text-align: center;

  h2 {
    @apply text-2xl text-light-blue-steel font-bold mb-6;
  }

  span {
    @apply text-base text-light-blue-steel;
  }
}