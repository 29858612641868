@use "src/styles/colors";
@use "src/styles/fonts";

.container {
  background-color: theme("colors.grey");
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  padding: 0 80px;
  position: relative;
  height: 382px;

  .close {
    position: absolute;
    top: theme("spacing.8");
    right: theme("spacing.8");

    svg {
      width: theme("spacing.8");
      height: theme("spacing.8");
    }
  }

  fieldset {
    margin: theme("spacing.8") 0;

    label {
      @apply text-sm text-blue-steel mb-4;
    }
  }
}
