@use "src/styles/colors";
@use "src/styles/fonts";

.attachments {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 6px;

  &-item {
    position: relative;
    min-width: 0;
    max-width: 100%;

    &-name {
      @apply text-xs w-full mt-2 text-ellipsis overflow-hidden whitespace-nowrap;
    }

    .media {
      // overide default Card min-width
      & > * {
        min-width: unset;
        height: auto;
      }
    }

    &:hover {
      .icon[class] {
        opacity: 1;
      }
    }

    .icon {
      &,
      &:focus,
      &:active {
        @apply top-3 right-3 w-8 h-8;
        opacity: 0;
        transition: opacity 0.2s linear;
        position: absolute;
        padding: 5px;
        background-color: colors.$white;
      }
    }
  }

  .actions {
    &[class] {
      @apply top-3 right-3;

      &:hover,
      &:focus,
      &:active {
        box-shadow: none;
      }

      &.active {
        opacity: 1;
      }
    }

    &-menu {
      z-index: 3;

      li {
        padding: 0;

        button {
          font-weight: inherit;
          color: inherit;
          transition: none;
        }

        &:last-child {
          padding: 0;
        }
      }
    }
  }
}
