@use "src/styles/fonts";
@use "src/styles/colors";

.prioritise-project {
  max-width: 465px;

  .title {
    span {
      @apply text-teal;
    }
  }

  .error {
    margin-top: theme("spacing.5");
    color: colors.$red6;
    @include fonts.little;
  }
}
