@use "src/styles/colors";
@use "src/styles/fonts";

.container {
  @apply font-sm;

  height: 80px;
  border: 1px solid theme("colors.light-blue-steel");
  border-radius: 10px;
  overflow: hidden;
  font-weight: 500;
  color: theme("colors.blue-steel");

  &.selected {
    outline: 2px solid theme("colors.teal");
    outline-offset: -1px;
  }

  .name {
    @apply font-regular;
    font-weight: 700;
    margin-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .selector {
    height: 80px;

    .select {
      top: 8px !important;
      left: 8px !important;
    }

    .star {
      top: 6px !important;
      right: 8px !important;
    }

    &:after {
      border: none !important;
      box-shadow: none !important;
    }
  }

  .image {
    width: 80px;
    min-width: 80px;
    height: 100%;
    min-height: 80px;
    object-fit: cover;
  }

  .tags {
    @apply flex gap-3 flex-wrap h-fit max-h-full overflow-hidden overflow-hidden;

    // max 3 lines of nested elements
    max-height: 56px;
    overflow: hidden;
  }
}