@use "src/styles/colors";
@use "src/styles/fonts";

.add-media {
  @apply border border-solid border-light-blue-steel p-9;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 15px;
  cursor: pointer;
  text-align: center;
  max-width: 100%;

  &-title {
    @apply text-base text-blue-steel font-medium mt-6;

    span {
      @apply text-teal;
    }
  }

  &-modal[class] {
    @apply w-10/12 h-5/6 flex flex-col pb-8;
  }
}
