@use "src/styles/colors";
@use "src/styles/variables";

.sidebar {
  position: absolute;
  top: 0;
  min-height: 100vh;
  height: 100%;
  width: variables.$sidebarWidth;
  overflow: auto;
  margin-left: -80px;

  @apply bg-white;
  box-shadow: 0px 10px 20px rgba(33, 0, 66, 0.1);

  &-content {
    min-height: calc(100vh - theme("spacing.9"));
    padding: theme("spacing.9");
    height: 100%;
    max-height: 100vh;
    width: variables.$sidebarWidth;
    position: fixed;
    width: variables.$sidebarWidth;
    display: flex;
    flex-direction: column;
  }
}

.main {
  width: 100%;

  &--sidebar {
    width: calc(100% - variables.$sidebarWidth);
    margin-left: variables.$sidebarWidth;
  }
}
