@use 'src/styles/colors';
@use 'src/styles/fonts';

.credits-summary {
  &-scope {
    margin: theme('spacing.8') 0;

    &__title {
      @apply text-sm text-blackberry font-medium text-ellipsis overflow-hidden;
    }

    &__service {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: theme('spacing.4') 0;

      span {
        @apply text-sm text-blue-steel;
        flex: 1 1 0;
        text-align: center;

        font-style: italic;
        text-align: left;

        &:last-of-type {

          font-style: normal;
          text-align: right;
        }

        &:first-of-type {
          flex-grow: 1.5;
          font-style: normal;

        }
      }
    }

  }
  &__total {
    @include fonts.medium;
    background-color: colors.$white;
    border-radius: theme("spacing.5");
    margin-top: theme("spacing.5");
    color: colors.$purple6;
    font-weight: fonts.$medium;
    padding: theme("spacing.7") theme("spacing.8");
  }
}
