@use "src/styles/colors";
@use "src/styles/fonts";

.update-credits {
  &-reason[class] {
    &,
    &:hover,
    &:focus {
      @apply text-blackberry border-light-blue-steel text-base font-regular w-full justify-between;

      svg path {
        @apply stroke-blue-steel;
      }
    }
  }
}
