@use "src/styles/colors";
@use "src/styles/fonts";

.header {
  position: relative;
  background-color: colors.$purple6;
  height: 550px;

  .info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    color: colors.$white;
    font-weight: fonts.$medium;
    text-align: center;
    white-space: nowrap;
    padding: theme("spacing.8");

    h5 {
      @include fonts.medium;
      margin-bottom: theme("spacing.6");
      overflow: hidden;
      text-overflow: ellipsis;
    }

    h4 {
      @include fonts.large;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.main {
  margin: 0 83px;
}

.asset-view {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: colors.$white;
  z-index: 2;
}
