@use "src/styles/colors";
@use "src/styles/fonts";

.settings-notifications {
  @apply text-blackberry;

  .description {
    @apply font-sm text-mid-blue-steel;
  }

  .section {
    @apply flex my-10;

    &-title {
      @apply font-l font-bold text-blackberry;
      width: 115px;
      margin-right: 100px;
    }

    &-field {
      @apply flex items-start mb-8;

      &-info {
        @apply ml-2 -mt-3;
      }

      &-label {
        @apply text-base font-bold text-blackberry;
      }

      &-description {
        @apply text-sm text-mid-blue-steel;
      }

      &-brands {
        @apply mt-4 py-5 px-6 -mt-7;
        width: 350px;
        margin-left: 30px;

        &-grid {
          @apply p-9 grid grid-cols-3 gap-8 overflow-auto;
          max-height: 400px;
          width: 600px;
        }
      }
    }
  }
}
