@use "src/styles/colors";
@use "src/styles/fonts";

.projectFeedback {
  h1 {
    @include fonts.medium;
    color: colors.$purple6;
    font-weight: fonts.$medium;
    margin-bottom: theme("spacing.6");
  }

  p {
    @include fonts.small;
  }
}

.feedbackList {
  align-items: flex-start;
  display: grid;
  grid-template-columns: 70% auto;
  justify-content: space-between;
}

.event {
  padding-bottom: theme("spacing.8");
  border-bottom: 1px solid colors.$grey5;
  margin-bottom: theme("spacing.8");
}
