@use "src/styles/colors";
@use "src/styles/fonts";

.brand-guidelines {
  &__input {
    width: 100%;
    margin-bottom: theme("spacing.5");

    input {

      &:focus,
      &:hover {
        @apply bg-white;
      }

    }

    &--filled {
      input {
        box-shadow: none;

        &:read-only {
          @apply text-blackberry;

          &~* {
            box-shadow: none;
          }
        }
      }
    }


    &--do {
      input {
        &:focus {
          box-shadow: 0 0 0 1px theme("colors.green");
        }
      }
    }

    &--do#{&}--filled {
      input {
        @apply bg-green/10;

        &:hover {
          box-shadow: 0 0 0 1px theme("colors.green");
        }

        &:focus {
          background-color: theme("colors.white");
        }
      }
    }

    &--dont {
      input {
        &:focus {
          box-shadow: 0 0 0 1px theme("colors.red");
          background-color: theme("colors.white");
        }
      }
    }

    &--dont#{&}--filled {
      input {
        @apply bg-red/10;

        &:hover {
          box-shadow: 0 0 0 1px theme("colors.red");
        }

        &:focus {
          background-color: theme("colors.white");
        }
      }
    }

    &--filled {
      input {
        &:read-only {
          &:hover {
            box-shadow: none;
          }
        }
      }
    }
  }
}
