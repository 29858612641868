@use 'src/styles/colors';
@use 'src/styles/fonts';

.authorization {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  height: 100%;

  h1 {
    @include fonts.primary;
    @include fonts.large;
    font-weight: fonts.$medium;
  }

  &__background {
    position: relative;

    video {
      position: absolute;
      height: 100%;
      min-width: 100%;
      object-fit: cover;
    }
  }

  &__content {
    /* Change because of wrong video color */

    @apply bg-white;
    padding: 0 118px;
  }

  &__logo {
    margin-top: 15vh;
    margin-bottom: 10vh;
  }
}
